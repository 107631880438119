import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { fetchTeamByTeamIdAndSeason } from '../services/TeamService';
import { Team } from '../models/Team';
import { Match } from '../models/Match';
import { mapMappings } from '../Constants';
import useLocalStorage from 'use-local-storage'; // Import useLocalStorage

interface MatchTableProps {
    matches: Match[];
    compact: boolean;
}

const MatchTable: React.FC<MatchTableProps> = ({ matches, compact }) => {
    const [teams, setTeams] = useState<{ [key: string]: Team }>({});
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isDark] = useLocalStorage<boolean>("isDark", false); // Retrieve isDark

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchTeams = async (teamIds: string[]) => {
            try {
                const fetchedTeams: { [key: string]: Team } = {};
                await Promise.all(teamIds.map(async (teamId) => {
                    if (!teams[teamId]) {
                        const team = await fetchTeamByTeamIdAndSeason(teamId, matches[0].season);
                        if (team) {
                            fetchedTeams[teamId] = team;
                        }
                    }
                }));
                setTeams({ ...teams, ...fetchedTeams });
            } catch (error) {
                console.error('Error fetching teams:', error);
            }
        };

        const teamIdsToFetch: string[] = matches.reduce((ids: string[], match) => {
            if (!teams[match.team1.teamId]) {
                ids.push(match.team1.teamId);
            }
            if (!teams[match.team2.teamId]) {
                ids.push(match.team2.teamId);
            }
            return ids;
        }, []);

        if (teamIdsToFetch.length > 0) {
            fetchTeams(teamIdsToFetch);
        }
    }, [matches, teams]);

    const sortedMatches = useMemo(() => {
        return [...matches].sort((a, b) => {
            return new Date(b.match_time).getTime() - new Date(a.match_time).getTime();
        });
    }, [matches]);

    if (sortedMatches.length === 0) {
        return (
            <div className="container text-center">
                <h1>Ingen kampe fundet</h1>
            </div>
        );
    }


    return (
        <div className="text-center">
            <table
                className={`table table-striped ${isDark ? 'table-dark' : ''}`}
                style={
                    isDark
                        ? {
                            border: '1px solid #111',
                            borderCollapse: 'collapse' as 'collapse', // Type assertion here
                        }
                        : undefined
                }
            >
                <thead>
                    <tr>
                        {!isMobile && !compact && <th style={{ width: '17.5%' }}>Dato</th>}
                        <th style={{ width: '25%' }}>Hold 1</th>
                        <th style={{ width: '15%' }}>Score</th>
                        <th style={{ width: '25%' }}>Hold 2</th>
                        {!isMobile && <th style={{ width: '17.5%' }}>Map</th>}
                    </tr>
                </thead>
                <tbody>
                    {sortedMatches.map((match) => (
                        <tr key={match.matchid} style={{ height: '80px' }}>
                            {!isMobile && !compact && (
                                <td className="align-middle">
                                    {new Date(match.match_time).toLocaleDateString('da-DK', {
                                        day: 'numeric',
                                        month: 'long',
                                    }) + ', ' + new Date(match.match_time).toLocaleTimeString('da-DK', {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        hour12: false
                                    })}
                                </td>
                            )}
                            <td className="align-middle">
                                <Link to={`/team/${match.team1.teamId}`} className={`text-decoration-none ${isDark ? 'text-light' : 'text-black'} d-flex flex-column align-items-center`}>
                                    <img
                                        src={teams[match.team1.teamId]?.teamLogo}
                                        alt={teams[match.team1.teamId]?.teamName}
                                        style={{ width: 40, height: 40, objectFit: 'contain' }}
                                        loading="lazy"
                                    />
                                    <span className="mt-1 text-truncate" style={{ maxWidth: '100%' }}>{teams[match.team1.teamId]?.teamName}</span>
                                </Link>
                            </td>
                            <td className="align-middle" style={{ height: '100%' }}>
                                <div style={{ fontSize: '1.5rem', fontWeight: 'normal' }}>
                                    <span className={match.team1won ? "text-success" : "text-danger"}>{match.team1score}</span>
                                    <span className="mx-2">-</span>
                                    <span className={match.team1won ? "text-danger" : "text-success"}>{match.team2score}</span>
                                </div>
                                <Link to={`/match/${match.matchid}`} className="btn btn-primary">Se kamp</Link>
                            </td>
                            <td className="align-middle">
                                <Link to={`/team/${match.team2.teamId}`} className={`text-decoration-none ${isDark ? 'text-light' : 'text-black'} d-flex flex-column align-items-center`}>
                                    <img
                                        src={teams[match.team2.teamId]?.teamLogo}
                                        alt={teams[match.team2.teamId]?.teamName}
                                        style={{ width: 50, height: 50, objectFit: 'contain' }}
                                        loading="lazy"
                                    />
                                    <span className="mt-1 text-truncate" style={{ maxWidth: '100%' }}>{teams[match.team2.teamId]?.teamName}</span>
                                </Link>
                            </td>
                            {!isMobile &&
                                <td className="align-middle">
                                    {match.bo3 ? 'BO3' : mapMappings.get(match.mapName) || match.mapName}
                                </td>
                            }
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default MatchTable;
